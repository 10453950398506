
export const environment = {
  production: true,
  mediaConfig: 'daimleragmemediabuild',
  apiBaseUrl:'https://api.media.mercedes-benz.com',
  mediaDeliveryUrl:'https://api.media.mercedes-benz.com/jsonapi/image/deliver',
  embedUrl:'https://stream.media.mercedes-benz.com',
  loginUrl:'https://login.media.mercedes-benz.com',
  CSRFTokenHeader:'X-MEMEDIA-CSRF-TOKEN',
  mapApiKey:'KLTSv_7zNkdeNbsBgsleldR_jbqQdskFGUN_8L3O_kg',
  sitesToTrack:'["media.mercedes-benz.com", "stream.media.mercedes-benz.com"]',
  connectionString:'',
  frontendUrl:'https://media.mercedes-benz.com'
};
